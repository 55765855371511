import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";

// components
import LabelErrorProvider from "../LabelErrorProvider";

// helpers
import { generateSelectStyles, Option, ValueContainer } from "./helpers";

// assets
import "../../styles/shared/select.scss";


function Select(props) {
  const {
    label,
    error,
    fullWidth,
    labelPlacement,
    closeMenuOnSelect,
    hideSelectedOptions = false,
    isSearchable = true,
    isMulti,
    wrapperClassName,
    components = {},
    options,
    onChange,
    value,
    type = "primary",
    ...rest
  } = props;
  const [selectValue, setSelectValue] = useState(value);

  const styles = generateSelectStyles({ fullWidth, hasError: !!error, type });

  const optionsToUse = isMulti
    ? [
      { label: "Select All", value: "select-all", },
      ...options,
    ]
    : options;

  const handleSelect = (newValue, event) => {
    if (event.option && event.option.value === "select-all") {
      const optionsFlat = options.map(item => item.options ? item.options : item).flat();

      const valueToSet = selectValue?.length === optionsFlat.length
        ? []
        : optionsFlat;

      setSelectValue(valueToSet);
      onChange && onChange(valueToSet, event);

      return;
    } else if (event.option && event.option.value === "all-advisors") {
      const currentSelectedAdvisors = selectValue.filter(item => item.key === "advisor");
      const advisors = options.find(item => item.label === "Advisors").options;

      const valueToSet = currentSelectedAdvisors.length === advisors.length - 1
        ? selectValue.filter(item => item.key !== "advisor" && item.value !== "all-advisors")
        : [
          ...selectValue.filter(item => item.key !== "advisor"),
          ...advisors,
        ];

      setSelectValue(valueToSet);
      onChange && onChange(valueToSet, event);

      return;
    } else if (event.option && event.option.value === "all-technicians") {
      const currentSelectedTechnicians = selectValue.filter(item => item.key === "technician");
      const technicians = options.find(item => item.label === "Technicians").options;

      const valueToSet = currentSelectedTechnicians.length === technicians.length - 1
        ? selectValue.filter(item => item.key !== "technician" && item.value !== "all-technicians")
        : [
          ...selectValue.filter(item => item.key !== "technician"),
          ...technicians,
        ];

      setSelectValue(valueToSet);
      onChange && onChange(valueToSet, event);

      return;
    }

    setSelectValue(newValue);
    onChange && onChange(newValue, event);
  };

  // effects
  useEffect(() => {
    if (typeof value !== "undefined") {
      setSelectValue(value);
    }
  }, [JSON.stringify(value)]);

  // renders
  return (
    <LabelErrorProvider
      label={label}
      error={error}
      labelPlacement={labelPlacement}
      className={wrapperClassName}
      fullWidth={fullWidth}
    >
      <ReactSelect
        isMulti={isMulti}
        styles={styles}
        isSearchable={isSearchable}
        closeMenuOnSelect={typeof closeMenuOnSelect !== "undefined" ? closeMenuOnSelect : !isMulti}
        hideSelectedOptions={hideSelectedOptions}
        options={optionsToUse}
        onChange={handleSelect}
        value={selectValue}
        components={{
          IndicatorSeparator: () => null,
          ClearIndicator: () => null,
          Option,
          ValueContainer,
          ...components,
        }}
        classNamePrefix="time-select"
        className="time-select"
        { ...rest }
      />
    </LabelErrorProvider>
  );
}

Select.propTypes = {
  labelPlacement: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  fullWidth: PropTypes.bool,
  menuIsOpen: PropTypes.bool,
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  onChange: PropTypes.func,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,
  placeholder: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default Select;
